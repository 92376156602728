@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Variables for default theme (light-mode) */
    --text-color: #081a3e;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  html.dark {
    --text-color: white;
  }

  body {
    min-height: 100vh;
    min-width: 1336px;
    font-family: "gilroy";
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    width: 100%;
    overflow-x: auto;
    letter-spacing: 0.02em;
    line-height: 1.74;
    text-shadow: 0px 0px 1px rgb(25 27 33 / 35%);
  }

  body {
    @apply bg-light dark:bg-dark;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--text-color);
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  textarea {
    cursor: auto;
  }

  input:focus,
  textarea:focus {
    outline: none;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 5px;
    border-radius: 99px;
    overflow: hidden;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    height: 10px;
    background-color: #f6f6f6;
    border-radius: 9999px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    margin: 2px 0 1px 2px;
    height: 5px;
    background-color: #f81734;
    border-radius: 0.9375rem;
  }

  canvas {
    width: 100% !important;
    height: 100% !important;
  }

  .slider {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    border-radius: 9999px;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #041527;
    cursor: pointer;
  }

  html.dark .slider::-webkit-slider-thumb {
    border: 2px solid #041527;
    background: white;
  }

  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04aa6d;
    cursor: pointer;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.even_grey > :nth-child(even) {
  background-color: #f0f2f5;
}

.odd_grey > :nth-child(odd) {
  background-color: #f0f2f5;
}

/* React Calendar */
.react-calendar {
  width: 300px;
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 16px;
}

.react-calendar button {
  background-color: transparent;
  border: none;
  outline: none;
  color: #000;
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  align-items: center;
  height: 42px;
}

.react-calendar__navigation__label {
  font-size: 18px;
  height: 42px;
}

.react-calendar__navigation__arrow {
  font-size: 32px;
  margin-bottom: 6px;
  width: 42px;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__month-view__weekdays {
  height: 42px;
  line-height: 42px;
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
}

.react-calendar__month-view__weekdays__weekday > * {
  text-decoration: none !important;
  font-size: 12px;
  color: #797b86;
}

.react-calendar__tile {
  width: 36px;
  height: 36px;
  border-radius: 6px;
  font-size: 16px;
  flex: 0 0 12.9857% !important;
}

.react-calendar__month-view__days,
.react-calendar__century-view__decades,
.react-calendar__year-view__months,
.react-calendar__decade-view__years {
  gap: 4px;
}

.react-calendar__month-view__days__day--neighboringMonthn {
  color: #abb0bd !important;
}

.react-calendar__tile:disabled {
  color: #abb0bd !important;
  pointer-events: none;
}
.react-calendar__tile--active {
  background-color: red !important;
  color: white !important;
}
.react-calendar__tile--hasActive.react-calendar__tile--now {
  background-color: red !important;
  color: white !important;
}
.react-calendar__tile--hasActive {
  background-color: red !important;
  color: white !important;
}

.react-calendar__tile--now {
  background-color: lightgray !important;
  color: white !important;
}

.react-calendar__tile:hover {
  background-color: red;
  color: white;
}

.react-calendar__tile.react-calendar__year-view__months__month,
.react-calendar__tile.react-calendar__century-view__decades__decade,
.react-calendar__tile.react-calendar__decade-view__years__year {
  height: 52px;
  flex: 0 0 32% !important;
}

.range_date_picker .react-calendar__tile--hover {
  position: relative;
  background: #e6e6e6 !important;
  border-radius: 0 !important;
  overflow: visible !important;
}

.range_date_picker .react-calendar__tile--active {
  position: relative;
  background: red !important;
  border-radius: 0 !important;
  overflow: visible !important;
}

.range_date_picker .react-calendar__tile--active::after,
.range_date_picker .react-calendar__tile--hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 90%;
  width: 20px;
  height: 100%;
  background: inherit !important;
  border-radius: 0 !important;
}

.range_date_picker .react-calendar__tile--rangeEnd::after,
.range_date_picker .react-calendar__tile--hoverEnd::after,
.range_date_picker .react-calendar__tile:nth-child(7n)::after {
  content: "";
  display: none;
}

.range_date_picker .react-calendar__tile--rangeEnd,
.range_date_picker .react-calendar__tile--hoverEnd {
  border-radius: 0 8px 8px 0 !important;
}

.range_date_picker .react-calendar__tile--rangeStart,
.range_date_picker .react-calendar__tile--hoverStart {
  border-radius: 8px 0 0 8px !important;
}

.range_date_picker
  .react-calendar__tile--hover
  + .react-calendar__tile--rangeStart {
  border-radius: 0 8px 8px 0 !important;
}

.react-calendar__tile.react-calendar__year-view__months__month > abbr {
  display: none;
}

/* React Calendar Ends */

.lg-thumb-item.active {
  filter: brightness(150%);
}

.fc-scroller.fc-scroller-liquid-absolute {
  overflow: hidden !important;
}

.dark .fc-daygrid-day.fc-day-today {
  background-color: rgb(255 255 255 / 15%) !important;
}
